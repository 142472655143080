





















































import type { PropType } from 'vue';
import { SfAccordion } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { AccordionItem } from '~/types/cms/AccordionItem';
import { SCHEMA_ITEM_PROP, SCHEMA_ITEM_TYPE } from '~/constants/seo';

export default defineComponent({
  name: 'Accordion',
  components: {
    SfAccordion,
  },
  props: {
    items: {
      type: Array as PropType<Array<AccordionItem>>,
      required: true,
    },
  },
  setup() {
    return {
      SCHEMA_ITEM_PROP,
      SCHEMA_ITEM_TYPE,
    };
  },
});
